import React from 'react'
import {Dialog} from 'primereact/dialog'
import {Button} from 'primereact/button'

const AcceptModal = ({isDisabled, isAcceptedModalOpen, onAccept}) => {
  return (
    <Dialog
      visible={isAcceptedModalOpen}
      onHide={() => {}}
      closable={false}
      draggable={false}
      className="max-w-[500px] mx-2 pb-4 bg-white w-4/5"
      header={
        <h2 className="text-xl font-semibold mt-2 text-black text-center">
          Ваш заказ принят 💐
        </h2>
      }
    >
      <div className="items-center flex flex-col space-y-6">
        <p className="px-7 text-center">
          Отправьте пожалуйста скриншот об оплате заказа вашему менеджеру
        </p>
        <Button
          onClick={onAccept}
          className={`w-4/5 border-none focus:outline-none font-bold disabled:text-gray-600 text-white rounded-md flex justify-center ${
            isDisabled ? 'bg-gray-300 cursor-not-allowed' : 'bg-slate-950'
          }`}
        >
          Готово!
        </Button>
      </div>
    </Dialog>
  )
}

export default AcceptModal
