import React from 'react'
import Router from './Router'

function App() {
  return (
    <div>
      <Router className="flex-1 overflow-x-hidden overflow-y-auto p-4" />
    </div>
  )
}

export default App
