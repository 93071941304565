import {Button} from 'primereact/button'
import {Checkbox} from 'primereact/checkbox'
import {Dialog} from 'primereact/dialog'
import {classNames} from 'primereact/utils'

const AdditionalItemsModal = ({
  openApproveModal,
  calculateSums,
  visible,
  toggleSelect,
  closeAdditionalModal,
  handleCloseSendOrder,
  additionalProducts,
  chosenAdditionalProducts,
}) => {
  const hasChosenItems = chosenAdditionalProducts.length > 0

  const onAccept = () => {
    calculateSums()
    closeAdditionalModal()
    openApproveModal()
  }
  return (
    <Dialog
      visible={visible}
      onHide={() => {
        handleCloseSendOrder()
        closeAdditionalModal()
      }}
      draggable={false}
      className="max-w-[500px] mx-2 bg-white w-5/6"
      header={<h2 className="text-xl font-semibold text-black">Выберите доп. товары</h2>}
    >
      <ul className={'flex flex-col space-y-6'}>
        {additionalProducts.map(additionalProduct => {
          const checked = chosenAdditionalProducts.some(
            chosenProduct => chosenProduct.id === additionalProduct.id,
          )
          return (
            <li className={'flex justify-between space-x-4'} key={additionalProduct.id}>
              <label>
                <Checkbox
                  checked={checked}
                  onChange={() => toggleSelect(additionalProduct)}
                  color="#05A87B"
                  pt={{
                    input: ({context}) => {
                      return context.checked
                        ? 'bg-green shadow-none border-none'
                        : 'shadow-none'
                    },
                  }}
                />
                <span className={classNames('ml-2.5', checked && 'text-green font-bold')}>
                  {additionalProduct.name}
                </span>
              </label>
              <span className={classNames(checked && 'text-green font-bold')}>
                {additionalProduct.price}
              </span>
            </li>
          )
        })}
      </ul>
      <Button
        onClick={onAccept}
        disabled={false}
        className={`mt-6 w-full border-none focus:outline-none font-bold disabled:text-gray-600 text-white rounded-md flex justify-center ${
          hasChosenItems ? 'bg-green cursor-not-allowed' : 'bg-black'
        }`}
      >
        {hasChosenItems ? 'Далее' : 'Не нужно'}
      </Button>
    </Dialog>
  )
}

export default AdditionalItemsModal
