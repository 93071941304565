import {useEffect, useState} from 'react'
import DealEndPoints from '../http/endpoints/dealEndPoints'
import ClientEndPoints from '../http/endpoints/clientEndPoints'
import FullscreenModal from '../components/FullscreenModal'
import ApprovePage from '../components/ApprovePage'
import {Button} from 'primereact/button'
import {Modals} from '../components/Modals'

const Deal = () => {
  const [data, setData] = useState({})
  const [newBonus, setNewBonus] = useState(0)
  const [totalCost, setTotalCost] = useState(0)
  const [selectedFlowers, setSelectedFlowers] = useState([])
  const [orderSent, setOrderSent] = useState(false)
  const [fullscreenProduct, setFullscreenProduct] = useState(null)
  const [images, setImages] = useState([])
  const [clientBonus, setClientBonus] = useState(0)

  const currentURL = window.location.href
  const parts = currentURL.split('/')
  const lastPart = parts[parts.length - 1]

  const products = data.products
  const additionalProducts = products?.filter(product => product.isAddProduct === true)
  const hasAdditionalItems = additionalProducts?.length > 0

  const chosenAdditionalProducts = selectedFlowers.filter(selectedFlower => {
    return additionalProducts.some(product => product.id === selectedFlower.id)
  })

  const isRafale = products?.map(product => product.brand).includes('1.Rafale')

  async function getDeal() {
    try {
      const response = await DealEndPoints.getDeal(lastPart)
      setData(response.data[0])
      const client = await ClientEndPoints.checkClient(response.data[0].phone)
      setClientBonus(client.data.bonus)
    } catch (error) {
      console.error('Ошибка при получении данных:', error)
    }
  }

  useEffect(() => {
    getDeal(lastPart)
  }, [])

  const openFullscreen = product => {
    const photos = []
    if (product.photo && typeof product.photo === 'string') {
      photos.push(product.photo)
    }
    if (product.photo1 && typeof product.photo1 === 'string') {
      photos.push(product.photo1)
    }
    if (product.photo2 && typeof product.photo2 === 'string') {
      photos.push(product.photo2)
    }
    if (product.photo3 && typeof product.photo3 === 'string') {
      photos.push(product.photo3)
    }
    if (product.photo4 && typeof product.photo4 === 'string') {
      photos.push(product.photo4)
    }
    setImages(photos)
    setFullscreenProduct(product)
  }

  const closeFullscreen = () => {
    setImages([])
    setFullscreenProduct(null)
  }

  const toggleSelect = flower => {
    if (selectedFlowers.some(selected => selected.id === flower.id)) {
      setSelectedFlowers(selectedFlowers.filter(selected => selected.id !== flower.id))
    } else {
      setSelectedFlowers([...selectedFlowers, flower])
    }
  }

  function formatCost(cost) {
    return cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' тг.'
  }

  const calculateSums = () => {
    let cost = 0
    selectedFlowers.forEach(product => {
      cost += Number(product.price)
    })
    setTotalCost(cost)
    setNewBonus(Math.floor(((cost * 0.05) / 5) * 5))
  }

  const handleSendOrder = () => {
    calculateSums()
    setOrderSent(true)
  }

  const handleCloseSendOrder = () => {
    setTotalCost(0)
    setNewBonus(0)
    setOrderSent(false)
  }

  return (
    <div className="relative">
      {data.details ? (
        <ApprovePage />
      ) : (
        <div className="container mx-auto p-4 max-w-[600px]">
          <h1 className="text-2xl font-bold text-gray-800">Здравствуйте!</h1>
          <div className="flex mt-4">
            <p>Количество доступных бонусов: </p>
            <p className="text-blue-500 text-l ml-2">{clientBonus || 0} тг.</p>
          </div>

          {fullscreenProduct && (
            <FullscreenModal
              fullscreenProduct={fullscreenProduct}
              images={images}
              closeFullscreen={closeFullscreen}
            />
          )}

          <div className="grid grid-cols-2 gap-9 mt-4">
            {data.products ? (
              data.products.map((flower, index) => (
                <div
                  className="bg-white overflow-hidden flex flex-col gap-y-2.5"
                  key={index}
                >
                  <img
                    src={flower.photo}
                    alt={flower.name}
                    className="w-full object-cover cursor-pointer rounded-md aspect-square"
                    onClick={() => openFullscreen(flower)}
                  />
                  <div>
                    <h2 className="text-s whitespace-no-wrap w-full text-black-800">
                      {flower.name}
                    </h2>
                    <p className="text-black-600 font-semibold">
                      {formatCost(flower.price)}
                    </p>
                  </div>

                  <Button
                    onClick={() => toggleSelect(flower)}
                    className={`px-auto py-2 rounded-md w-full mt-auto flex shadow-none justify-center h-10 ${
                      selectedFlowers.some(selected => selected.id === flower.id)
                        ? 'bg-green text-slate-50'
                        : 'bg-slate-50 text-green border border-green'
                    }`}
                  >
                    {selectedFlowers.some(selected => selected.id === flower.id) ? (
                      <i className="pi pi-check"></i>
                    ) : (
                      'Выбрать'
                    )}
                  </Button>
                </div>
              ))
            ) : (
              <div className="flex justify-center w-full col-span-2 my-10">
                <Spinner />
              </div>
            )}
          </div>

          <div
            className="sticky bottom-0 bg-white py-2 mt-10 block"
            style={{position: '-webkit-sticky'}}
          >
            <Button
              onClick={handleSendOrder}
              disabled={selectedFlowers.length === 0}
              className={`w-full border-none focus:outline-none font-bold disabled:text-gray-600 text-white rounded-md flex justify-center ${
                selectedFlowers.length === 0
                  ? 'bg-gray-300 cursor-not-allowed'
                  : 'bg-slate-950'
              }`}
            >
              Подтвердить заказ
            </Button>
          </div>

          {orderSent && (
            <Modals
              isRafale={isRafale}
              calculateSums={calculateSums}
              data={data}
              handleCloseSendOrder={handleCloseSendOrder}
              selectedFlowers={selectedFlowers}
              totalCost={totalCost}
              newBonus={newBonus}
              clientBonus={clientBonus}
              hasAdditionalItems={hasAdditionalItems}
              additionalProducts={additionalProducts}
              chosenAdditionalProducts={chosenAdditionalProducts}
              toggleSelect={toggleSelect}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default Deal

const Spinner = () => {
  return (
    <div role="status">
      <svg
        aria-hidden="true"
        class="inline w-20 h-20 text-gray-200 animate-spin fill-green"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
      <span class="sr-only">Loading...</span>
    </div>
  )
}
