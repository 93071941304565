import React from 'react'
import {useRoutes} from 'react-router'
import {Helmet} from 'react-helmet'
import Deal from './pages/Deal'
import {useLocation} from 'react-router-dom'

export const RouteList = [
  {
    path: '/deal/:id',
    title: 'Коммерческое предложение',
    element: <Deal />,
  },
]

const Router = () => {
  const routeElement = useRoutes(RouteList)
  const location = useLocation()
  const firstSegment = location.pathname.split('/')[1]
  const currentRoute = RouteList.find(route => route.path.split('/')[1] === firstSegment)
  return (
    <>
      <Helmet>
        <title>{currentRoute?.title || 'Заголовок по умолчанию'}</title>
      </Helmet>
      {routeElement}
    </>
  )
}

export default Router
