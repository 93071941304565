import AcceptModal from './AcceptModal'
import AdditionalItemsModal from './AdditionalItemsModal'
import {useState} from 'react'
import ApproveModal from './ApproveModal'

export const Modals = ({
  isRafale,
  calculateSums,
  data,
  userPhone,
  handleCloseSendOrder,
  selectedFlowers,
  totalCost,
  newBonus,
  clientBonus,
  hasAdditionalItems,
  additionalProducts,
  chosenAdditionalProducts,
  toggleSelect,
}) => {
  const [isAcceptedModalOpen, setIsAcceptedModalOpen] = useState(false)
  const [isAdditionalItemsModalOpen, setIsAdditionalItemsModalOpen] =
    useState(hasAdditionalItems)
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(!hasAdditionalItems)
  const closeAdditionalModal = () => {
    setIsAdditionalItemsModalOpen(false)
  }

  return (
    <>
      {isApproveModalOpen && (
        <ApproveModal
          data={data}
          userPhone={userPhone}
          handleCloseSendOrder={handleCloseSendOrder}
          selectedFlowers={selectedFlowers}
          totalCost={totalCost}
          newBonus={newBonus}
          clientBonus={clientBonus}
          isRafale={isRafale}
          setIsAcceptedModalOpen={setIsAcceptedModalOpen}
        />
      )}

      {isAcceptedModalOpen && (
        <AcceptModal
          isDisabled={selectedFlowers.length === 0}
          isAcceptedModalOpen={isAcceptedModalOpen}
          onAccept={() => {
            setIsAcceptedModalOpen(false)
            setIsApproveModalOpen(false)
            handleCloseSendOrder()
          }}
        />
      )}

      {isAdditionalItemsModalOpen && (
        <AdditionalItemsModal
          openApproveModal={() => setIsApproveModalOpen(true)}
          calculateSums={calculateSums}
          toggleSelect={toggleSelect}
          additionalProducts={additionalProducts}
          chosenAdditionalProducts={chosenAdditionalProducts}
          visible={!isAcceptedModalOpen && hasAdditionalItems}
          closeAdditionalModal={closeAdditionalModal}
          handleCloseSendOrder={handleCloseSendOrder}
        />
      )}
    </>
  )
}
