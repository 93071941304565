import React, {useState} from 'react'
import InputMask from 'react-input-mask'
import {Dialog} from 'primereact/dialog'
import {Dropdown} from 'primereact/dropdown'
// import DealEndPoints from '../http/endpoints/dealEndPoints';
import {Checkbox} from 'primereact/checkbox'
import DealEndPoints from '../http/endpoints/dealEndPoints'

const ApproveModal = ({
  data,
  userPhone,
  isRafale,
  handleCloseSendOrder,
  selectedFlowers,
  totalCost,
  newBonus,
  clientBonus,
  setIsAcceptedModalOpen,
}) => {
  const defaultAddress = isRafale
    ? 'Проспект Абая, 164/8 к8 (со ст. Туркебаева)'
    : 'Микрорайон Керемет, 5 к13 185 офис; 1 этаж'
  const kaspiLink = isRafale
    ? 'https://pay.kaspi.kz/pay/nreo0wyt'
    : 'https://pay.kaspi.kz/pay/xfbjimlf'

  const [newCost, setNewCost] = useState(totalCost)
  const [receiverName, setReceiverName] = useState('')
  const [receiverNameError, setReceiverNameError] = useState('')
  const [receiverPhone, setReceiverPhone] = useState('7')
  const [receiverPhoneError, setReceiverPhoneError] = useState('')
  const [receiverAddress, setReceiverAddress] = useState(defaultAddress)
  const [receiverAddressError, setReceiverAddressError] = useState('')
  const [receiverTime, setReceiverTime] = useState('')
  const [receiverTimeError, setReceiverTimeError] = useState('')
  const [receiverComment, setReceiverComment] = useState('')
  const [receiverPaymentMethod, setReceiverPaymentMethod] = useState('Kaspi')
  const [receiverPaymentMethodError, setReceiverPaymentMethodError] = useState('')
  const [receiverDeliveryMethod, setReceiverDeliveryMethod] = useState('232')
  const [receiverDeliveryMethodError, setReceiverDeliveryMethodError] = useState('')
  const [writeOffBonuses, setWriteOffBonuses] = useState(false)
  const [newBonus2, setNewBonus2] = useState(newBonus)
  const currentDate = new Date()
  currentDate.setMonth(currentDate.getMonth() + 5)
  const day = currentDate.getDate().toString().padStart(2, '0')
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
  const year = currentDate.getFullYear()
  const formattedDate = `${day}.${month}.${year}`

  // const handleDateChange = e => {
  //   const value = e.target.value
  //   setReceiverTime(value)
  //   setReceiverTimeError(value.trim() ? '' : 'Поле обязательно для заполнения')
  // }

  const handleDateChange = e => {
    const value = e.target.value
    const selectedDate = new Date(value) // Преобразуем введенное значение в объект Date
    const currentTime = new Date() // Текущее время

    // Добавляем два часа к текущему времени
    currentTime.setHours(currentTime.getHours() + 2)

    if (selectedDate > currentTime) {
      setReceiverTime(value)
      setReceiverTimeError('')
    } else {
      setReceiverTime('')
      setReceiverTimeError(
        'Выберите другое время. Доставляем заказы не менее чем за 2 часа.',
      )
    }
  }

  const handleReceiverName = e => {
    const value = e.target.value
    setReceiverName(value)
    setReceiverNameError(value.trim() ? '' : 'Поле обязательно для заполнения')
  }

  const handleReceiverAddress = e => {
    const value = e.target.value
    setReceiverAddress(value)
    setReceiverAddressError(value.trim() ? '' : 'Поле обязательно для заполнения')
  }

  const handleReceiverComment = e => {
    const value = e.target.value
    setReceiverComment(value)
  }

  const handleReceiverPhone = e => {
    const value = e.target.value
    setReceiverPhone(value)
    setReceiverPhoneError(value.trim() ? '' : 'Поле обязательно для заполнения')
  }

  const handleReceiverPaymentMethod = e => {
    const value = e.value
    setReceiverPaymentMethod(value)
    setReceiverPaymentMethodError(value.trim() ? '' : 'Поле обязательно для заполнения')
  }

  const handleReceiverDeliveryMethod = e => {
    const value = e.value
    setReceiverDeliveryMethod(value)
    if (value === '232') {
      setNewCost(prevValue => prevValue - 2200)
      setReceiverAddress(defaultAddress)
    } else if (value === '231') {
      setNewCost(prevValue => prevValue + 2200)
      setReceiverAddress('')
    }

    setReceiverDeliveryMethodError(value.trim() ? '' : 'Поле обязательно для заполнения')
  }

  const handleCheckboxChange = () => {
    setWriteOffBonuses(!writeOffBonuses)
    if (!writeOffBonuses) {
      setNewCost(prevCost => {
        const updatedCost = prevCost - data.bonus
        if (updatedCost >= 0) {
          setNewBonus2(Math.floor(((updatedCost * 0.05) / 5) * 5))
        } else {
          setNewBonus2(0)
          return 0
        }
        return updatedCost
      })
    } else {
      setNewCost(totalCost)
      setNewBonus2(newBonus)
    }
  }

  const confirmOrder = async () => {
    const fields = [
      {value: receiverName, errorSetter: setReceiverNameError},
      {value: receiverAddress, errorSetter: setReceiverAddressError},
      {value: receiverTime, errorSetter: setReceiverTimeError},
      {value: receiverPaymentMethod, errorSetter: setReceiverPaymentMethodError},
      {value: receiverDeliveryMethod, errorSetter: setReceiverDeliveryMethodError},
    ]

    let hasError = false
    for (const field of fields) {
      if (!field.value.trim()) {
        hasError = true
        field.errorSetter('Поле обязательно для заполнения')
      }
    }
    if (receiverPhone.length < 16) {
      setReceiverPhoneError('Поле обязательно для заполнения')
      hasError = true
    }
    if (hasError) return 0

    const productIds = selectedFlowers.map(item => item.id)
    const newDeal = {
      deal: {
        id: data.dealId,
        status: 'notPaid',
        products: productIds,
        details: {
          receiverName: receiverName,
          receiverTime: receiverTime,
          receiverPhone: receiverPhone,
          receiverAddress: receiverAddress,
          receiverComment: receiverComment,
          receiverPaymentMethod: receiverPaymentMethod,
          receiverDeliveryMethod: receiverDeliveryMethod,
          writeOffBonuses: writeOffBonuses ? data.bonus : 0,
        },
      },
      client: {
        id: data.clientId,
        bonus: newBonus,
      },
    }
    //
    await DealEndPoints.updateDeal(newDeal)
    // const phone = userPhone ? userPhone : '77775724444'
    if (receiverPaymentMethod === 'Kaspi') {
      window.location.href = kaspiLink
    }
    // else
    //   window.location.href = `https://wa.me/${phone}?text=%D0%A1%D0%BF%D0%BE%D1%81%D0%BE%D0%B1%20%D0%BE%D0%BF%D0%BB%D0%B0%D1%82%D1%8B%20%22%D0%94%D1%80%D1%83%D0%B3%D0%BE%D0%B9%22`
    setIsAcceptedModalOpen(true)
  }

  const getCurrentTimePlusTwoHours = () => {
    const currentTime = new Date()
    // Получаем текущее время в часовом поясе Алматы (GMT+6)
    currentTime.setHours(currentTime.getHours() + 5)
    // Добавляем два часа к текущему времени
    const ct = currentTime.setHours(currentTime.getHours() + 2)
    // Форматируем в формат "YYYY-MM-DDTHH:MM" для поля datetime-local
    return currentTime.toISOString().slice(0, 16)
  }

  return (
    <Dialog
      blockScroll={true}
      visible={true}
      onHide={handleCloseSendOrder}
      className={'max-w-[500px] mx-2 pb-4 bg-white w-full'}
      draggable={false}
      header={
        <h2 className="text-xl font-semibold mt-2 text-black">Проверьте Ваш заказ!</h2>
      }
    >
      <h3 className="text-sm  mb-2">Выбранные букеты:</h3>
      {selectedFlowers.map((product, index) => (
        <p className="text-sm font-bold mb-2" key={product.id}>
          {index + 1}.{product.name}
        </p>
      ))}

      <ul className="flex flex-col gap-y-4">
        <li>
          <label className="text-sm">Способ доставки:</label>
          <Dropdown
            pt={{
              item: 'py-1',
              root: 'focus:ring focus:ring-cyan-50 shadow-none',
              input: 'py-1.5 focus:ring focus:ring-cyan-50 focus:outline-none',
              panel: 'p-0',
              list: 'py-1',
            }}
            value={receiverDeliveryMethod}
            onChange={handleReceiverDeliveryMethod}
            options={RECEIVER_DELIVERY_OPTIONS}
            className="w-full"
          />
          {receiverDeliveryMethodError && (
            <p style={{color: 'red'}}>{receiverDeliveryMethodError}</p>
          )}
        </li>

        <li>
          <label className="text-sm">Имя получателя:</label>
          <InputMask
            value={receiverName}
            onChange={handleReceiverName}
            className="mt-1 w-full py-2 px-3 mb-2 text-xs rounded-md border border-gray-300 focus:ring focus:ring-cyan-50 focus:outline-none focus:border-blue-500"
          />
          {receiverNameError && <p style={{color: 'red'}}>{receiverNameError}</p>}
        </li>

        <li>
          <label className="text-sm">Телефон получателя:</label>
          <InputMask
            value={receiverPhone}
            onChange={handleReceiverPhone}
            mask="+9(999)999-99-99"
            maskChar=" "
            className="mt-1 w-full py-2 px-3 mb-2 text-xs rounded-md border border-gray-300 focus:ring focus:ring-cyan-50 focus:outline-none focus:border-blue-500"
            placeholder="+7(___)___-__-__"
          />
          {receiverPhoneError && <p style={{color: 'red'}}>{receiverPhoneError}</p>}
        </li>

        <li>
          <label className="text-sm">Адрес получателя:</label>
          <InputMask
            value={receiverAddress}
            onChange={handleReceiverAddress}
            maskChar=" "
            className="mt-1 w-full py-2 px-3 mb-2 text-xs rounded-md border border-gray-300 focus:ring focus:ring-cyan-50 focus:outline-none focus:border-blue-500"
          />
          {receiverAddressError && <p style={{color: 'red'}}>{receiverAddressError}</p>}
        </li>

        <li>
          <label className="text-sm">Время доставки:</label>
          <input
            type="datetime-local"
            id="datetime"
            value={receiverTime}
            min={getCurrentTimePlusTwoHours()}
            onChange={handleDateChange}
            className="block w-full text-xs mt-2 p-2 border rounded focus:ring focus:ring-cyan-50 focus:outline-none focus:border-blue-500"
          />
          {receiverTimeError && <p style={{color: 'red'}}>{receiverTimeError}</p>}
        </li>

        <li>
          <label className="text-sm">Текст записки:</label>
          <InputMask
            value={receiverComment}
            onChange={handleReceiverComment}
            maskChar=" "
            className="mt-1 w-full py-2 px-3 mb-2 text-xs rounded-md border border-gray-300 focus:ring focus:ring-cyan-50 focus:outline-none focus:border-blue-500"
          />
        </li>

        <li>
          <label className="text-sm">Способ оплаты:</label>
          <Dropdown
            pt={{
              item: 'py-1',
              root: 'focus:ring focus:ring-cyan-50 shadow-none',
              input: 'py-1.5 focus:ring focus:ring-cyan-50 focus:outline-none',
              panel: 'p-0',
              list: 'py-1',
            }}
            value={receiverPaymentMethod}
            onChange={handleReceiverPaymentMethod}
            options={RECEIVER_PAYMENT_OPTIONS}
            className="mt-1 w-full"
          />

          {receiverPaymentMethodError && (
            <p style={{color: 'red'}}>{receiverPaymentMethodError}</p>
          )}
        </li>
      </ul>

      <label className="flex items-center mt-6 justify-between">
        <div className="flex items-center">
          <Checkbox
            checked={writeOffBonuses}
            onChange={handleCheckboxChange}
            color="#05A87B"
            pt={{
              input: ({context}) => {
                return context.checked
                  ? 'bg-green shadow-none border-none'
                  : 'shadow-none'
              },
            }}
          />
          <span className="text-gray-700 ml-2 text-sm">Списать бонусы</span>
        </div>
        <p className="text-sm font-semibold">{formatCost(clientBonus)}</p>
      </label>

      <ul className="space-y-3.5 mt-3">
        <div className="flex justify-between">
          <p className="text-sm">К оплате: </p>
          <p className="text-sm text-green font-semibold ml-2">{formatCost(newCost)}</p>
        </div>
        <div className="flex justify-between">
          <p className="text-sm">Бонусы к начислению: </p>
          <p className="text-sm text-green font-semibold ml-2">
            + {formatCost(newBonus2)}
          </p>
        </div>
        <div className="flex justify-between">
          <p className="text-sm">Действительны до: </p>
          <p className="text-sm text-green font-semibold ml-2">{formattedDate}</p>
        </div>
      </ul>

      <button
        onClick={confirmOrder}
        className="bg-green text-white mt-7 w-full px-2 py-2 rounded-md"
      >
        Оплатить
      </button>
    </Dialog>
  )
}

export default ApproveModal

const RECEIVER_PAYMENT_OPTIONS = [
  {
    value: 'Kaspi',
    label: 'Kaspi bank',
  },
  {
    value: 'RocketPay',
    label: 'RocketPay',
  },
  {
    value: 'PayPal',
    label: 'PayPal',
  },
  {
    value: 'Other',
    label: 'Карта другого банка',
  },
]

const RECEIVER_DELIVERY_OPTIONS = [
  {
    value: '232',
    label: 'Самовывоз',
  },
  {
    value: '231',
    label: 'Курьером ( +2200 тг. )',
  },
]

function formatCost(cost) {
  if (cost === null) {
    cost = 0
  }
  return cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' тг.'
}
